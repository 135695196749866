import {
  type App,
  type DeriveTableProps,
  type Plugin,
  Table,
} from "@pimo/pimo-app-builder";
import { ChipCell, GridLayout, TextCardCell } from "@pimo/pimo-components";
import { GIAMUtils } from "@pimo/strapi-types-and-utils";
import {
  type Currency,
  KOVRR_RECOMMENDED_ACTION_LEVEL_COLOR_MAP,
  KOVRR_RECOMMENDED_ACTION_LEVEL_MAP,
  KOVRR_RECOMMENDED_ACTION_MAP,
  type OE,
  QuestionnaireResponse,
} from "crq-types";
import {
  convertCurrency,
  formatCurrency,
  formatDate,
  formatEffect,
  getLatestSuccessfulCRQRun,
} from "crq-utils";
import { generatePath } from "react-router-dom";

import { CRQAppState } from "../app";
import {
  CRQLineChartCard,
  CRQLineChartCardEventMap,
} from "../components/crq-line-chart";
import { CRQQuantificationDialog } from "../components/crq-quantification-dialog";
import { CRQRadialBarChart } from "../components/crq-radial-bar-chart";
import { CRQTitleCard, CRQTitleCardProps } from "../components/crq-title-card";
import { CRQEffectTrending } from "../components/crq-trending";
import { APP_ROUTES } from "../constants";
import { buildFormRoute } from "../form/build-form-route";
import {
  createCRQRun,
  fetchOE,
  fetchProgram,
  fetchQuestionnaireForOE,
} from "../helpers/fetch-helpers";
import { theme } from "../theme";
import {
  getMillionsString,
  getSeriesDataForYearByMonths,
  getStartDateTimestamp,
} from "../utils";
import { getCRQRunPropertyArray } from "../utils/get-crq-run-property-array";

// eslint-disable-next-line
export interface OEReportPluginState {}

export class OEReportPlugin
  implements Plugin<CRQAppState, OEReportPluginState>
{
  onRegister(app: App<CRQAppState>): void {
    const view = app.createView({
      name: "OE Report",
      layout: new GridLayout(),
    });

    const quantificationDialog = view.addComponent({
      component: CRQQuantificationDialog,
      layoutProps: {
        xs: 12,
      },
    });

    quantificationDialog.mapVisibility(({ showQuantificationDialog }) => {
      return showQuantificationDialog;
    });

    quantificationDialog.on("cancel", () => {
      app.patchAppState({ showQuantificationDialog: false });
    });

    quantificationDialog.on("submit", async () => {
      const { currentOE } = app.getAppState();

      if (currentOE?.id == null) {
        return;
      }

      await createCRQRun(currentOE?.id);

      const [updatedCurrentOE, updatedCurrentQuestionnaire] = await Promise.all(
        [fetchOE(currentOE.id), fetchQuestionnaireForOE(currentOE.id)]
      );

      app.patchAppState({
        currentOE: updatedCurrentOE,
        currentQuestionnaire: updatedCurrentQuestionnaire,
        showQuantificationDialog: false,
      });
    });

    const titleCard = view.addComponent({
      component: CRQTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    titleCard.mapState(
      ({
        currentCurrency,
        currentOE,
        currentQuestionnaire,
        userProfile,
        program,
      }): CRQTitleCardProps => {
        const currencies = new Set<Currency>(
          ["EUR", currentQuestionnaire?.currency].filter(Boolean) as Currency[]
        );
        const ids = (currentOE?.CRQRuns?.filter(({ id }) => id != null).map(
          ({ id }) => id
        ) ?? []) as number[];
        const lastestID = Math.max(...ids);
        const latestCRQRun = currentOE?.CRQRuns?.find(
          ({ id }) => id === lastestID
        );
        const latestSuccessfulCRQRun = getLatestSuccessfulCRQRun(
          currentOE ?? ({} as OE)
        );
        const nameOftheCurrentOE = currentQuestionnaire?.oe?.name ?? "";
        const active = currentOE?.active ?? true;
        const kovrrID = currentQuestionnaire?.oe?.kovrrID ?? "";
        const kovrrLink = ["https://www.kovrr.app/company-management", kovrrID]
          .filter(Boolean)
          .join("/");

        const giamInstanceUtils = new GIAMUtils("crq");

        const groups = userProfile?.groups ?? [];

        const oeNames =
          (app
            .getAppState()
            .OEs.map((oe) => oe.name)
            .filter(Boolean) as string[]) || [];

        const hasUserWriteAccess = giamInstanceUtils.doesUserHaveAccessToEntity(
          {
            entityName: currentOE?.name ?? "",
            allEntityNames: oeNames,
            userGroups: groups,
            action: "write",
            entityType: "OE",
          }
        );

        return {
          hasWriteAccess: hasUserWriteAccess,
          active,
          currency: currentCurrency,
          currencies: Array.from(currencies),
          externalLink: {
            href: kovrrLink,
            text: "More Details (go to Kovrr)",
          },
          questionnaireSubmittedAt: formatDate(
            currentQuestionnaire?.questionnaireSubmittedAt ?? ""
          ),
          questionnaireSubmittedBy:
            currentQuestionnaire?.questionnaireSubmittedBy ?? "",
          status: latestCRQRun?.status,
          title: [
            `CRQ Report -`,
            nameOftheCurrentOE,
            !active && "(Draft)",
            latestSuccessfulCRQRun?.date &&
              `(Run as of ${formatDate(latestSuccessfulCRQRun.date)})`,
          ]
            .filter(Boolean)
            .join(" "),
          currentQuestionnaire:
            currentQuestionnaire ?? ({} as QuestionnaireResponse),
          reportingDate: program?.reportingDate,
        };
      }
    );

    titleCard.on("edit-button:click", () => {
      const { currentOE } = app.getAppState();

      app.navigate(
        generatePath(APP_ROUTES.editQuestionnaire, {
          id: String(currentOE?.id),
        })
      );
    });

    titleCard.on("run-button:click", () => {
      const { currentOE } = app.getAppState();

      if (currentOE?.id == null) {
        return;
      }

      app.patchAppState({
        showQuantificationDialog: true,
      });
    });

    titleCard.on("select:change", ({ payload }) => {
      if (!payload) {
        return;
      }

      app.patchAppState({
        currentCurrency: payload,
      });
    });

    const annualAverageLossComponent = view.addComponent({
      component: CRQLineChartCard,
      layoutProps: {
        xs: 4,
      },
    });

    annualAverageLossComponent.mapState(
      ({ currentCurrency, currentOE, currentQuestionnaire, currentYear }) => {
        const currency = currentQuestionnaire?.currency;
        const dates = getCRQRunPropertyArray(
          currentOE?.CRQRuns ?? [],
          "date"
        ) as string[];
        const data = getSeriesDataForYearByMonths(
          currentOE?.CRQRuns ?? [],
          "annualAverageLoss",
          currentYear,
          currency !== currentCurrency ? currency : undefined
        );
        const { annualAverageLoss, rates } =
          getLatestSuccessfulCRQRun(currentOE ?? ({} as OE)) ?? {};

        return {
          currency: currentCurrency,
          currentYear,
          dates: data.map((d) => (d ? d?.date : null)),
          endDate: new Date(),
          headline:
            currency && currentCurrency && annualAverageLoss != null
              ? getMillionsString({
                  currency: currentCurrency,
                  number:
                    currency !== currentCurrency
                      ? convertCurrency({
                          currency,
                          rates,
                          value: +annualAverageLoss,
                        })
                      : +annualAverageLoss,
                })
              : "n/a",
          infoText: [
            "The average loss across every annual scenario in the simulation. It represents the expected loss for your organization over the next year.",
            "At the top you can see the result of the most recent run. The graph shows the results of the current and previous runs.",
          ].join("\n\n"),
          series: [
            {
              id: "1",
              data: [null, ...data.map((d) => (d ? d?.value : null))],
              label: "Average Annual Loss",
              color: "#02B2AF",
              connectNulls: true,
            },
          ],
          startDate: new Date(getStartDateTimestamp(dates)),
          title: "Average Annual Loss",
        };
      }
    );

    const oneInTwentyYearLossComponent = view.addComponent({
      component: CRQLineChartCard,
      layoutProps: {
        xs: 4,
      },
    });

    oneInTwentyYearLossComponent.mapState(
      ({ currentCurrency, currentOE, currentQuestionnaire, currentYear }) => {
        const currency = currentQuestionnaire?.currency;
        const dates = getCRQRunPropertyArray(
          currentOE?.CRQRuns ?? [],
          "date"
        ) as string[];
        const data = getSeriesDataForYearByMonths(
          currentOE?.CRQRuns ?? [],
          "lossEventProbability",
          currentYear,
          currency !== currentCurrency ? currency : undefined
        );
        const { lossEventProbability, rates } =
          getLatestSuccessfulCRQRun(currentOE ?? ({} as OE)) ?? {};

        return {
          currency: currentCurrency,
          currentYear,
          dates: data.map((d) => (d ? d?.date : null)),
          endDate: new Date(),
          headline:
            currency && currentCurrency && lossEventProbability != null
              ? getMillionsString({
                  currency: currentCurrency,
                  number:
                    currency !== currentCurrency
                      ? convertCurrency({
                          currency,
                          rates,
                          value: +lossEventProbability,
                        })
                      : +lossEventProbability,
                })
              : "n/a",
          infoText: [
            "The loss value for which there is a 5% likelihood to be exceeded in an annual scenario in the simulation. It indicates the loss arising from a severe loss scenario occurring once in 20 years.",
            "At the top you can see the result of the most recent run. The graph shows the results of the current and previous runs.",
          ].join("\n\n"),
          series: [
            {
              id: "1",
              data: [null, ...data.map((d) => (d ? d?.value : null))],
              label: "1-in-20 Years Loss",
              connectNulls: true,
              color: "#02B2AF",
            },
          ],
          startDate: new Date(getStartDateTimestamp(dates)),
          title: "1-in-20 Years Loss",
        };
      }
    );

    const extremeLossScenarioComponent = view.addComponent({
      component: CRQLineChartCard,
      layoutProps: {
        xs: 4,
      },
    });

    extremeLossScenarioComponent.mapState(
      ({ currentCurrency, currentOE, currentQuestionnaire, currentYear }) => {
        const currency = currentQuestionnaire?.currency;
        const dates = getCRQRunPropertyArray(
          currentOE?.CRQRuns ?? [],
          "date"
        ) as string[];
        const data = getSeriesDataForYearByMonths(
          currentOE?.CRQRuns ?? [],
          "highExposureLoss",
          currentYear,
          currency !== currentCurrency ? currency : undefined
        );
        const { highExposureLoss, rates } =
          getLatestSuccessfulCRQRun(currentOE ?? ({} as OE)) ?? {};

        return {
          currency: currentCurrency,
          currentYear,
          dates: data.map((d) => (d ? d?.date : null)),
          endDate: new Date(),
          headline:
            currency && currentCurrency && highExposureLoss != null
              ? getMillionsString({
                  currency: currentCurrency,
                  number:
                    currency !== currentCurrency
                      ? convertCurrency({
                          currency,
                          rates,
                          value: +highExposureLoss,
                        })
                      : +highExposureLoss,
                })
              : "n/a",
          infoText: [
            "The loss value for which there is a 1% likelihood to be exceeded in an annual scenario in the simulation. It indicates the loss arising from an extreme loss scenario occurring once in 100 years.",
            "At the top you can see the result of the most recent run. The graph shows the results of the current and previous runs.",
          ].join("\n\n"),
          series: [
            {
              id: "1",
              data: [null, ...data.map((d) => (d ? d?.value : null))],
              label: "1-in-100 Years Loss",
              connectNulls: true,
              color: "#02B2AF",
            },
          ],
          startDate: new Date(getStartDateTimestamp(dates)),
          title: "1-in-100 Years Loss",
        };
      }
    );

    [
      annualAverageLossComponent,
      oneInTwentyYearLossComponent,
      extremeLossScenarioComponent,
    ].forEach((component) =>
      component.on("change-time", (scale) => {
        const payload =
          scale.payload as CRQLineChartCardEventMap["change-time"];
        if (!payload) return;

        app.setAppState({
          ...app.getAppState(),
          ...payload,
        });
      })
    );

    const annualEventsLikelihoodComponent = view.addComponent({
      component: CRQRadialBarChart,
      layoutProps: {
        xs: 4,
      },
    });

    annualEventsLikelihoodComponent.mapState(({ currentOE }) => {
      const { targetedAnnualRate } =
        getLatestSuccessfulCRQRun(currentOE ?? ({} as OE)) ?? {};

      return {
        colors: [theme.palette.primary.main],
        infoText: [
          "The likelihood of experiencing successful cyber events next year. This is calculated as the number of successful events in the simulation divided by the size of the simulation.",
          "A successful cyber event is defined as an attack that breached the organization's defenses and caused a financial impact through extortion, interruption or data theft. For data theft, this includes a minimum loss of 1% of the organization’s individual data. For interruption, it includes a minimum outage of 1 hour.",
        ].join("\n\n"),
        labels: targetedAnnualRate ? [`${targetedAnnualRate}%`] : [],
        series: targetedAnnualRate ? [targetedAnnualRate] : [],
        title: "Annual Events Likelihood",
      };
    });

    annualEventsLikelihoodComponent.mapVisibility(() => {
      return false;
    });

    const topRecommendedActionsTableDefinition = [
      { component: TextCardCell },
      { component: ChipCell },
      { component: ChipCell },
      { component: TextCardCell },
      { component: TextCardCell },
    ] as const;

    const topRecommendedActionsTable = new Table(
      topRecommendedActionsTableDefinition,
      "report"
    );

    const topRecommendedActionsTableComponent = view.addComponent<
      DeriveTableProps<typeof topRecommendedActionsTable>,
      unknown,
      unknown
    >({
      component: topRecommendedActionsTable,
      layoutProps: {
        xs: 12,
      },
    });

    topRecommendedActionsTableComponent.mapVisibility(({ currentOE }) => {
      const { recommendedActions = [] } =
        getLatestSuccessfulCRQRun(currentOE ?? ({} as OE)) ?? {};

      return !!recommendedActions.length;
    });

    topRecommendedActionsTableComponent.mapState(
      ({ currentCurrency, currentOE, currentQuestionnaire }) => {
        const currency = currentQuestionnaire?.currency;
        const {
          annualAverageLoss = "",
          highExposureLoss = "",
          rates,
          recommendedActions = [],
        } = getLatestSuccessfulCRQRun(currentOE ?? ({} as OE)) ?? {};

        return {
          title: "Top Recommended Actions",
          infoIconText: [
            "This represents the top mitigation actions recommended to reduce the identified risks. For each CIS control, the expected reduction in the annual cost of cyber incidents is simulated, assuming that the respective implementation group is improved. The Top 3 CIS controls with the highest effect in terms of the Average Annual Loss are shown in the table.",
            [
              "- Current IG: Current implementation group of the corresponding CIS Control based on the input questionnaire.",
              "- Target IG: New implementation group of the corresponding CIS Control after mitigation actions have been taken.",
              "- Average Effect: Expected reduction in the Average Annual Loss when using the Target IG compared to the Current IG.",
              "- High Effect: Expected reduction in the Extreme Loss Scenario when using the Target IG compared to the Current IG.",
            ].join("\n"),
          ].join("\n\n"),
          tableHeaderEntries: [
            "CIS Control",
            "Current IG",
            "Target IG",
            "Average Effect",
            "High Effect (1:100 loss)",
          ],
          data: (recommendedActions ?? []).map(
            ({
              averageEffect,
              control,
              currentLevel,
              highEffect,
              targetLevel,
            }) => {
              const { abbr, name } = KOVRR_RECOMMENDED_ACTION_MAP[control];

              return {
                columnProps: [
                  {
                    header: abbr,
                    body: name,
                  },
                  {
                    body: KOVRR_RECOMMENDED_ACTION_LEVEL_MAP[currentLevel],
                    chipProps: {
                      sx: {
                        borderRadius: "12px",
                        background:
                          KOVRR_RECOMMENDED_ACTION_LEVEL_COLOR_MAP[
                            currentLevel
                          ],
                        color: "#fff",
                      },
                    },
                  },
                  {
                    body: KOVRR_RECOMMENDED_ACTION_LEVEL_MAP[targetLevel],
                    chipProps: {
                      sx: {
                        borderRadius: "12px",
                        background:
                          KOVRR_RECOMMENDED_ACTION_LEVEL_COLOR_MAP[targetLevel],
                        color: "#fff",
                      },
                    },
                  },
                  {
                    header: formatCurrency({
                      currency: currentCurrency,
                      value: Math.round(
                        currency !== currentCurrency
                          ? convertCurrency({
                              currency,
                              rates,
                              value: +averageEffect,
                            })
                          : +averageEffect
                      ),
                    }),
                    body: formatEffect({
                      dividend: +averageEffect,
                      divisor: +annualAverageLoss,
                    }),
                    endIcon: (
                      <CRQEffectTrending
                        dividend={+averageEffect}
                        divisor={+annualAverageLoss}
                      />
                    ),
                  },
                  {
                    header: formatCurrency({
                      currency: currentCurrency,
                      value: Math.round(
                        currency !== currentCurrency
                          ? convertCurrency({
                              currency,
                              rates,
                              value: +highEffect,
                            })
                          : +highEffect
                      ),
                    }),
                    body: formatEffect({
                      dividend: +highEffect,
                      divisor: +highExposureLoss,
                    }),
                    endIcon: (
                      <CRQEffectTrending
                        dividend={+highEffect}
                        divisor={+highExposureLoss}
                      />
                    ),
                  },
                ],

                rowProps: {},
              };
            }
          ),
        };
      }
    );

    const reportRoute = app.createRoute<"id">({
      path: APP_ROUTES.report,
      view,
    });

    reportRoute.on("load", async (params) => {
      const id = params.payload?.parameters?.id;

      if (id == null) {
        return;
      }

      const [currentOE, currentQuestionnaire, program] = await Promise.all([
        fetchOE(Number(id)),
        fetchQuestionnaireForOE(Number(id)),
        fetchProgram(),
      ]);

      app.patchAppState({
        currentOE,
        currentCurrency: currentQuestionnaire?.currency,
        currentQuestionnaire,
        program,
      });
    });

    buildFormRoute(app, reportRoute);
  }
}
