import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Markdown, Tooltip } from "@pimo/pimo-components";
import type {
  Currency,
  KovrrCompanyStatus,
  Program,
  QuestionnaireResponse,
} from "crq-types";
import { getDraftStatus, getStatus } from "crq-utils";
import { type FC, useState } from "react";

import { CRQStatusIndicator } from "./crq-status-indicator";

export type CRQTitleCardEndSlotProps = {
  externalLink?: {
    href: string;
    text: string;
  };
  currencies?: Currency[];
  currency?: Currency;
  onSelectChange: (event: SelectChangeEvent<Currency>) => void;
  onEditClick: () => void;
  onRunClick: () => void;
  questionnaireSubmittedAt?: string;
  questionnaireSubmittedBy?: string;
  status?: KovrrCompanyStatus;
  active?: boolean;
  currentQuestionnaire: QuestionnaireResponse;
  reportingDate: Program["reportingDate"];
  hasWriteAccess: boolean;
};

function getSubmitButtonTextByStatus(status?: KovrrCompanyStatus) {
  switch (status) {
    case "data_collection":
      return "Currently collecting data";
    case "ready_for_fq":
    case "running_fq":
      return "Currently running quantification";
    case "completed_fq":
    default:
      return "Run additional quantification";
  }
}

const INFO_TEXT = `
## Cyber Risk Quantification (CRQ)

### CRQ Report

The **CRQ report** provides key cyber risk quantification results based on the latest Kovrr model run. The CRQ reports includes the different risk figures resp. quantiles from the loss curve (average loss, 95%-quantile and 99%-quantile), the likelihood of experiencing successful cyber events as well as the top mitigation actions recommended based on the risk reduction. You can see the result of the most recent run (see ‘Run as of’) as well as the results of previous runs.

### Kovrr platform

Visit the **Kovrr platform** for more details and insights on the CRQ results and further analysis by clicking on the link in the header (access via SSO). The login to the Kovrr application works via SSO. For example, the Kovrr platform includes the following features 
- Overall loss curve including different impact categories such as ransomware, business interruption, data theft etc.
- Benchmarking of cyber risk with peers and other companies
- Simulation of all CIS control improvements in terms of risk reduction
- History of quantification runs including explanation of changes in the results
- And much more…

### Edit Input Questionnaire
By clicking on the **‘EDIT INPUT QUESTIONNAIRE’** button you can adjust the input questionnaire for your OE. You can adjust the input data for the CRQ model at any time, if needed. However, keep in mind that the updated input data **will be automatically used in the next regular quantification run** at the end of the month. **If you only want to adjust the input data for test purposes, e.g. to perform a test run, please contact [isrm@allianz.com](mailto:isrm@allianz.com)**.

### Selection of currency
The CRQ results in the report can be displayed in **your local currency and in EUR amounts**. The local currency is exactly the same as the currency you selected in the questionnaire under ‘Company Data’. If your local currency is not available in the questionnaire and you have selected EUR, only EUR values will be displayed in the report.

### Run additional quantification
**First please note that there are monthly automatically triggered CRQ model runs on the 25th of each month.** The results of each regular run are displayed in the CRQ report. If you want to trigger an additional CRQ model run, e.g. between two regular runs or after updating the input data, you just have to click on the run button. The results of the run will be displayed in the platform after a certain period of time.

**If you only want to perform a test run and not a productive run (e.g. simulation of future potential control improvements), please contact isrm@allianz.com.**

### Further details
On the [CRQ Connect page](https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/Cyberrisk-Quantification.aspx) you will find **more information and background** on CRQ, including training materials and more details on the Kovrr tool and methodology.
`;

export const CRQTitleCardEndSlot: FC<CRQTitleCardEndSlotProps> = ({
  currency,
  currencies = [],
  externalLink,
  onEditClick,
  onRunClick,
  onSelectChange,
  currentQuestionnaire,
  questionnaireSubmittedAt,
  questionnaireSubmittedBy,
  status,
  active,
  reportingDate,
  hasWriteAccess,
}) => {
  const disabled =
    !active || !hasWriteAccess || (status ? status !== "completed_fq" : false);

  const text = getSubmitButtonTextByStatus(status);

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  const tooltipString = [
    questionnaireSubmittedAt && `as of ${questionnaireSubmittedAt}`,
    questionnaireSubmittedBy && `by ${questionnaireSubmittedBy}`,
  ]
    .filter(Boolean)
    .join(" ");

  const updateStatus = !active
    ? getDraftStatus(currentQuestionnaire ?? null)
    : (getStatus(
        reportingDate ?? null,
        currentQuestionnaire?.isDataUpToDate ?? false
      ) ?? "unknown");

  return (
    <>
      <Box sx={{ alignItems: "center", display: "flex", gap: 2 }}>
        {externalLink && (
          <a
            href={externalLink.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#003781" }}
          >
            <Typography>{externalLink.text}</Typography>
          </a>
        )}
        <Tooltip title={tooltipString}>
          <Button onClick={onEditClick} sx={{ height: 36 }} variant="outlined">
            <Box sx={{ display: "flex", gap: 1 }}>
              Edit Input Questionnaire
              <CRQStatusIndicator status={updateStatus} />
            </Box>
          </Button>
        </Tooltip>
        <Select
          onChange={onSelectChange}
          sx={{ height: 36 }}
          value={currency ?? ""}
        >
          {currencies.map((currency) => (
            <MenuItem key={currency} value={currency}>
              Show {currency}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          onClick={(e) => setPopoverAnchor(e.target as HTMLButtonElement)}
        >
          <InfoOutlined />
        </IconButton>
        <Popover
          open={!!popoverAnchor}
          anchorEl={popoverAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setPopoverAnchor(null)}
        >
          <Typography component="div" sx={{ maxWidth: "500px", px: 2 }}>
            <Markdown>{INFO_TEXT}</Markdown>
          </Typography>
        </Popover>
      </Box>
      <Button
        disabled={disabled}
        onClick={onRunClick}
        sx={{
          background: disabled ? "#ffffff" : "#003781",
          borderRadius: 20,
          bottom: 40,
          boxShadow: "3px 4px 10px grey",
          color: "#ffffff",
          fontSize: "12px",
          height: 40,
          opacity: 1,
          position: "absolute",
          right: 40,
          zIndex: 1000,
          ":hover": {
            background: "#ffffff",
            color: "#003781",
          },
        }}
        variant="outlined"
      >
        {text}
      </Button>
    </>
  );
};
