import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AdditionalFormFieldProps,
  BaseFormFieldDefinition,
} from "@pimo/pimo-components/src/lib/pimo-components/pimo-form/form-field";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { aggregationLogic } from "./ig-aggregation-logic";
import { subControls } from "./subcontrols";

// eslint-disable-next-line react-refresh/only-export-components
export const CIS_CONTROL_ASSESSMENT_FIELD_TYPE =
  "cisControlAssessment" as const;

interface CISControlAssessmentFieldProps
  extends BaseFormFieldDefinition<typeof CIS_CONTROL_ASSESSMENT_FIELD_TYPE> {
  value?: string;
}

export const CISControlAssessmentField = ({
  name,
  errorMessage,
  label,
  disabled,
}: CISControlAssessmentFieldProps & AdditionalFormFieldProps) => {
  const { control } = useFormContext();
  const controlNumber = parseInt(name.replace("CISControl", "")) ?? 1;

  const [open, setOpen] = useState(false);
  const [selectedSubControls, setSelectedSubControls] = useState<string[]>([]);
  const [calculatedIG, setCalculatedIG] = useState<string>("Not Implemented");

  useEffect(() => {
    if (selectedSubControls.length > 0) {
      calculateIG();
    } else {
      setCalculatedIG("Not Implemented");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubControls]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSubControls([]);
    setCalculatedIG("");
  };

  const handleCheckboxChange = (id: string) => {
    if (!disabled) {
      setSelectedSubControls((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
      );
    }
  };

  const calculateIG = useCallback(() => {
    const logic = aggregationLogic[controlNumber];
    if (!logic) {
      setCalculatedIG("Not Implemented");
    }

    const implemented = selectedSubControls;
    if (logic.IG3.every((sub) => implemented.includes(sub))) {
      setCalculatedIG("IG3");
      return;
    }
    if (logic.IG2.every((sub) => implemented.includes(sub))) {
      setCalculatedIG("IG2");
      return;
    }

    if (
      logic.IG1.length === 0 ||
      logic.IG1.every((sub) => implemented.includes(sub))
    ) {
      setCalculatedIG("IG1");
      return;
    }

    setCalculatedIG("Not Implemented");
  }, [controlNumber, selectedSubControls]);

  useEffect(() => {
    calculateIG();
  }, [calculateIG]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const handleTakeValues = () => {
          field.onChange(calculatedIG);
          setOpen(false);
        };

        return (
          <FormControl fullWidth>
            <Stack direction="row" alignItems="stretch" spacing={2}>
              <Select
                label="Assessment"
                value={(field.value as string) || ""}
                onChange={(e) => !disabled && field.onChange(e.target.value)}
                displayEmpty
                disabled={disabled}
                sx={{ flex: "1 1 100%" }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Not Implemented">Not Implemented</MenuItem>
                <MenuItem value="IG1">IG1</MenuItem>
                <MenuItem value="IG2">IG2</MenuItem>
                <MenuItem value="IG3">IG3</MenuItem>
              </Select>
              <Button
                variant="outlined"
                onClick={handleOpen}
                sx={{ flex: "0 0 150px" }}
              >
                Detailed Input
              </Button>
            </Stack>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}

            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="md"
              fullWidth
              scroll="paper"
            >
              <DialogTitle>{label}</DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>In Place</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subControls[controlNumber]?.map((subControl) => (
                      <TableRow
                        key={subControl.id}
                        sx={{ cursor: disabled ? "default" : "pointer" }}
                      >
                        <TableCell>
                          <strong>{subControl.id}:</strong> {subControl.name}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={selectedSubControls.includes(
                              subControl.id
                            )}
                            onChange={() => handleCheckboxChange(subControl.id)}
                            disabled={disabled}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    gap: 3,
                    alignItems: "center",
                    paddingLeft: 2,
                    marginTop: 3,
                    marginBottom: 1,
                  }}
                  alignItems="center"
                >
                  <Box sx={{ fontWeight: "bold" }}>
                    Result of current selection:
                    <Chip
                      color={
                        calculatedIG === "IG1"
                          ? "error"
                          : calculatedIG === "IG2"
                            ? "warning"
                            : calculatedIG === "IG3"
                              ? "success"
                              : "default"
                      }
                      sx={{
                        color:
                          calculatedIG === "IG1" || calculatedIG === "IG3"
                            ? "#ffffff"
                            : undefined,
                        marginLeft: 2,
                        fontWeight: "normal",
                      }}
                      label={calculatedIG}
                    />
                  </Box>
                  <Box sx={{ flex: "1 1 auto" }}></Box>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    variant="contained"
                    onClick={handleTakeValues}
                    disabled={!calculatedIG}
                  >
                    Take Values
                  </Button>
                </Stack>
              </DialogActions>
            </Dialog>
          </FormControl>
        );
      }}
    ></Controller>
  );
};
